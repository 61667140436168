import './vili-card.scss';

import React from 'react';
import RatingStars from '../RatingStars/RatingStars';
import tt from '../../helpers/translation';
import logopaw from '../../images/logos/logopaw.png';
import logofl from '../../images/logos/logofl.png';
import logocaribe from '../../images/logos/logocaribe.png';

import mansionlucy from '../../images/logos/ML.png';
import elpaso from '../../images/logos/EP.png';
import goldriver from '../../images/logos/GR.png';
import parking from '../../images/logos/caravanning.png';
import colorado from '../../images/logos/CC.png';
import portaventura from '../../images/logos/HPA.png';
import caribe from '../../images/logos/CA.png';

export const ViliCard = props => {
  const {
    data: { title, stars, description, price, paw_logo, fl_logo, caribe_logo },
    type,
    locale,
  } = props;

  const calculateLogos = () => {
    const arrLogos = [];
    if (paw_logo || fl_logo || caribe_logo) {
      if (paw_logo === 'true') {
        arrLogos.push(logopaw);
      }
      if (fl_logo === 'true') {
        arrLogos.push(logofl);
      }
      if (caribe_logo === 'true') {
        arrLogos.push(logocaribe);
      }
      return arrLogos.map(element => (
        <div className="logo-container">
          <img src={element} alt="logo" />
        </div>
      ));
    }
    let logo;
    const titleVili = tt(title.toLowerCase());
    switch (true) {
      case titleVili.includes('lucy'):
        logo = mansionlucy;
        break;
      case titleVili.includes('el paso'):
        logo = elpaso;
        break;
      case titleVili.includes('parking'):
        logo = parking;
        break;
      case titleVili.includes('colorado'):
        logo = colorado;
        break;
      case titleVili.includes('portaventura'):
        logo = portaventura;
        break;
      case titleVili.includes('river'):
        logo = goldriver;
        break;
      case titleVili.includes('caribe'):
        logo = caribe;
        break;
      default:
        logo = 'none';
    }
    return (
      <div className="logo-container">
        <img src={logo} alt="logo" />
      </div>
    );
  };

  return (
    <div className="vili-card-container">
      <div className="logos-container">{calculateLogos()}</div>
      <div className={`vili-card-main ${type || ''}`}>
        <div className="title-container">{title}</div>
        {stars === 0 || stars === undefined ? null : <RatingStars count={stars} color="#5e5e5e" />}
        {description && <div className="summary-container">{description}</div>}
        {price && (
          <div className="price-container">
            <span className="since">{tt('Desde', locale).toUpperCase()}</span>
            <span className="number-vili" dangerouslySetInnerHTML={{
                  __html: price,
                }} ></span>
            { price.includes("class=") ? null :
            <span className="money">€</span>}
          </div>
        )}
      </div>
    </div>
  );
};
