import React from 'react';

import Icon from '../../helpers/icon';
import Buttons from '../Buttons/Buttons';
import Pawlink from '../PawLink/PawLink';
import pysIcons from '../../icons/pys-icons';
import tt from '../../helpers/translation';

import './Idun.scss';

const Idun = props => {
  const { cta, ctaText, title, page, locale } = props.data;

  const detectIcons = page => {
    let iconPartner;
    let iconReason;
    let textPartner;
    let textReason;
    switch (page) {
      case 'familia-1-dia':
        iconPartner = 'en-familia';
        iconReason = 'escapada-visita-1-dia';
        textPartner = tt('EN FAMILIA', locale);
        textReason = tt('1 DÍA', locale);
        break;
      case 'familia-escapada':
        iconPartner = 'en-familia';
        iconReason = 'escapada-visita-1-dia';
        textPartner = tt('EN FAMILIA', locale);
        textReason = tt('ESCAPADA', locale);
        break;
      case 'familia-vacaciones':
        iconPartner = 'en-familia';
        iconReason = 'vacaciones';
        textPartner = tt('EN FAMILIA', locale);
        textReason = tt('VACACIONES', locale);
        break;
      case 'amigos-1-dia':
        iconPartner = 'con-amigos';
        iconReason = 'escapada-visita-1-dia';
        textPartner = tt('CON AMIGOS', locale);
        textReason = tt('1 DÍA', locale);
        break;
      case 'amigos-escapada':
        iconPartner = 'con-amigos';
        iconReason = 'escapada-visita-1-dia';
        textPartner = tt('CON AMIGOS', locale);
        textReason = tt('ESCAPADA', locale);
        break;
      case 'amigos-vacaciones':
        iconPartner = 'con-amigos';
        iconReason = 'vacaciones';
        textPartner = tt('CON AMIGOS', locale);
        textReason = tt('VACACIONES', locale);
        break;
      default:
        iconPartner = 'con-amigos';
        iconReason = 'vacaciones';
        textPartner = tt('CON AMIGOS', locale);
        textReason = tt('VACACIONES', locale);
    }
    return (
      <div className="idun-icons">
        <div className="icon-full">
          <div className="icon-single-first">
            <Icon icon={iconPartner} width="100" height="30" color="#5e5e5e" iconSet={pysIcons} />
          </div>
          <div className="icon-text">{textPartner}</div>
        </div>
        <div className="icon-full">
          <div className="icon-single">
            <Icon icon={iconReason} width="100" height="30" color="#5e5e5e" iconSet={pysIcons} />
            {textReason === tt('ESCAPADA', locale) && <div className="number-container">+2</div>}
            {textReason === tt('VACACIONES', locale) && <div className="number-container">+3</div>}
          </div>
          <div className="icon-text">{textReason}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="idun-container">
      <span className="idun-title">{title}</span>
      {detectIcons(page)}

      <Buttons
        named
        link="plan_your_stay"
        size="small"
        color="white"
        text={tt('CAMBIAR PLAN', locale)}
      />
      {/* </Pawlink> */}
    </div>
  );
};

export default Idun;
