import tt from './translation';

export const filters = {
  tickets: (array, page, locale) => {
    let filtered;
    let finalData = [];
    switch (page) {
      case 'familia-1-dia':
        filtered = array.map(e => e.node.Entradas.filter(a => a.pysFilter.includes('tarifa_familia_dia'))).filter(e => e.length);
        filtered.map((e) => {
          e.map((a) => {
            a.title = a.title + ' ' + a.subTitle;
            if (a.discount_logo) a.price = a.third_rate_price_web
            finalData.push(a);
          });
        });
        finalData = finalData.filter(e => e.locale_to_display === '' || e.locale_to_display === locale)
        return finalData;
      case 'familia-escapada':
        filtered = array.map(e => e.node.Entradas.filter(a => a.pysFilter.includes('tarifa_familia_escapada'))).filter(e => e.length);
        filtered.map((e) => {
          e.map((a) => {
            a.title = a.title + ' ' + a.subTitle;
            if (a.discount_logo) a.price = a.third_rate_price_web
            finalData.push(a);
          });
        });
        finalData = finalData.filter(e => e.locale_to_display === '' || e.locale_to_display === locale)
        return finalData;
      case 'familia-vacaciones':
        filtered = array.map(e => e.node.Entradas.filter(a => a.pysFilter.includes('tarifa_familia_vacaciones'))).filter(e => e.length);
        filtered.map((e) => {
          e.map((a) => {
            a.title = a.title + ' ' + a.subTitle;
            if (a.discount_logo) a.price = a.third_rate_price_web
            finalData.push(a);
          });
        });
        finalData = finalData.filter(e => e.locale_to_display === '' || e.locale_to_display === locale)
        return finalData;
      case 'amigos-1-dia':
        filtered = array.map(e => e.node.Entradas.filter(a => a.pysFilter.includes('tarifa_amigos_dia'))).filter(e => e.length);
        filtered.map((e) => {
          e.map((a) => {
            a.title = a.title + ' ' + a.subTitle;
            if (a.discount_logo) a.price = a.third_rate_price_web
            finalData.push(a);
          });
        });
        finalData = finalData.filter(e => e.locale_to_display === '' || e.locale_to_display === locale)
        return finalData;
      case 'amigos-escapada':
        filtered = array.map(e => e.node.Entradas.filter(a => a.pysFilter.includes('tarifa_amigos_escapada'))).filter(e => e.length);
        filtered.map((e) => {
          e.map((a) => {
            a.title = a.title + ' ' + a.subTitle;
            if (a.discount_logo) a.price = a.third_rate_price_web
            finalData.push(a);
          });
        });
        finalData = finalData.filter(e => e.locale_to_display === '' || e.locale_to_display === locale)
        return finalData;
      case 'amigos-vacaciones':
        filtered = array.map(e => e.node.Entradas.filter(a => a.pysFilter.includes('tarifa_amigos_vacaciones'))).filter(e => e.length);
        filtered.map((e) => {
          e.map((a) => {
            a.title = a.title + ' ' + a.subTitle;
            if (a.discount_logo) a.price = a.third_rate_price_web
            finalData.push(a);
          });
        });
        finalData = finalData.filter(e => e.locale_to_display === '' || e.locale_to_display === locale)
        return finalData;
      default:
        filtered = array.map(e => e.node.Entradas.filter(a => a.pysFilter.includes('tarifa_familia_dia'))).filter(e => e.length);
        filtered.map((e) => {
          e.map((a) => {
            a.title = a.title + ' ' + a.subTitle;
            if (a.discount_logo) a.price = a.third_rate_price_web
            finalData.push(a);
          });
        });
        finalData = finalData.filter(e => e.locale_to_display === '' || e.locale_to_display === locale)
        return finalData;
    }
  },
  hotel: (array, page) => {
    let filtered;
    const finalData = [];
    switch (page) {
      case 'familia-escapada':
        return array;
      case 'familia-vacaciones':
        return array;
      case 'amigos-escapada':
        return array;
      case 'amigos-vacaciones':
        return array;
      default:
        return array;
    }
  },
  promotion_entradas: (array, page) => {
    const obj = {
      isSlider: true,
    };

    switch (page) {
      case 'familia-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_ticket_familia_dia'));
        return obj;
      case 'familia-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_ticket_familia_escapada'));
        return obj;
      case 'familia-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_ticket_familia_vacaciones'));
        return obj;
      case 'amigos-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_ticket_amigos_dia'));
        return obj;
      case 'amigos-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_ticket_amigos_escapada'));
        return obj;
      case 'amigos-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_ticket_amigos_vacaciones'));
        return obj;
      default:
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_ticket_familia_dia'));
        return obj;
    }
  },
  promotion_hotel: (array, page) => {
    const obj = {
      isSlider: true,
    };

    switch (page) {
      case 'familia-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_hotel_familia_dia'));
        return obj;
      case 'familia-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_hotel_familia_escapada'));
        return obj;
      case 'familia-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_hotel_familia_vacaciones'));
        return obj;
      case 'amigos-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_hotel_amigos_dia'));
        return obj;
      case 'amigos-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_hotel_amigos_escapada'));
        return obj;
      case 'amigos-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_hotel_amigos_vacaciones'));
        return obj;
      default:
        obj.cards = array.cards.filter(e => e.pysFilter.includes('promotion_hotel_familia_dia'));
        return obj;
    }
  },
  restaurants: (array, page) => {
    const obj = {
      isSlider: true,
    };

    switch (page) {
      case 'familia-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('restaurante_familia_dia'));
        return obj;
      case 'familia-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('restaurante_familia_escapada'));
        return obj;
      case 'familia-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('restaurante_familia_vacaciones'));
        return obj;
      case 'amigos-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('restaurante_amigos_dia'));
        return obj;
      case 'amigos-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('restaurante_amigos_escapada'));
        return obj;
      case 'amigos-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('restaurante_amigos_vacaciones'));
        return obj;
      default:
        obj.cards = array.cards.filter(e => e.pysFilter.includes('restaurante_familia_dia'));
        return obj;
    }
  },
  rides: (array, page) => {
    const obj = {
      isSlider: true,
    };

    switch (page) {
      case 'familia-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('atraccion_familia_dia'));
        return obj;
      case 'familia-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('atraccion_familia_escapada'));
        return obj;
      case 'familia-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('atraccion_familia_vacaciones'));
        return obj;
      case 'amigos-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('atraccion_amigos_dia'));
        return obj;
      case 'amigos-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('atraccion_amigos_escapada'));
        return obj;
      case 'amigos-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('atraccion_amigos_vacaciones'));
        return obj;
      default:
        obj.cards = array.cards.filter(e => e.pysFilter.includes('atraccion_familia_dia'));
        return obj;
    }
  },
  shows: (array, page) => {
    const obj = {
      isSlider: true,
    };

    switch (page) {
      case 'familia-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('espectaculo_familia_dia'));
        return obj;
      case 'familia-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('espectaculo_familia_escapada'));
        return obj;
      case 'familia-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('espectaculo_familia_vacaciones'));
        return obj;
      case 'amigos-1-dia':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('espectaculo_amigos_dia'));
        return obj;
      case 'amigos-escapada':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('espectaculo_amigos_escapada'));
        return obj;
      case 'amigos-vacaciones':
        obj.cards = array.cards.filter(e => e.pysFilter.includes('espectaculo_amigos_vacaciones'));
        return obj;
      default:
        obj.cards = array.cards.filter(e => e.pysFilter.includes('espectaculo_familia_dia'));
        return obj;
    }
  },
  destination: (array, page, arraySport, locale) => {
    const obj = {
      isSlider: true,
    };
    let result;
    const familia_escapada_ids = [770, 458, 461, 462, 768, tt('GIMNASIOS', locale)];
    const familia_vacaciones_ids = [768, 769, 773, 771, 770, 458, 459, 461, 462, tt('WELLNESS', locale), tt('LUMINE MEDITERRÁNEA BEACH & GOLF COMMUNITY', locale)];
    const amigos_escapada_ids = [773, 771, 458, 460, 461, tt('GIMNASIOS', locale)];
    const amigos_vacaciones_ids = [773, 771, 772, 770, 458, 460, 461, 462, tt('WELLNESS', locale), tt('LUMINE MEDITERRÁNEA BEACH & GOLF COMMUNITY', locale)];
    switch (page) {
      case 'familia-escapada':
        obj.cards = array.cards.filter(e => familia_escapada_ids.includes(e.pysFilter));
        result = arraySport.cards.filter(e => familia_escapada_ids.includes(e.title));
        result.map(e => obj.cards.push(e));
        return obj;
      case 'familia-vacaciones':
        obj.cards = array.cards.filter(e => familia_vacaciones_ids.includes(e.pysFilter));
        result = arraySport.cards.filter(e => familia_vacaciones_ids.includes(e.title));
        result.map(e => obj.cards.push(e));
        return obj;
      case 'amigos-escapada':
        obj.cards = array.cards.filter(e => amigos_escapada_ids.includes(e.pysFilter));
        result = arraySport.cards.filter(e => amigos_escapada_ids.includes(e.title));
        result.map(e => obj.cards.push(e));
        return obj;
      case 'amigos-vacaciones':
        obj.cards = array.cards.filter(e => amigos_vacaciones_ids.includes(e.pysFilter));
        result = arraySport.cards.filter(e => amigos_vacaciones_ids.includes(e.title));
        result.map(e => obj.cards.push(e));
        return obj;
      default:
        obj.cards = array.cards.filter(e => familia_escapada_ids.includes(e.pysFilter));
        result = arraySport.cards.filter(e => familia_escapada_ids.includes(e.title));
        result.map(e => obj.cards.push(e));
        return obj;
    }
  },
};
