import React from 'react';
import { graphql } from 'gatsby';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import Layout from '../src/components/Layout/Layout';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Idun from '../src/components/Idun/Idun';
import { Vili } from '../src/components/Vili/Vili';
import { Freya } from '../src/components/Freya/Freya';
import SEO from '../src/helpers/seo';
import Hod from '../src/components/Hod/Hod';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import tt from '../src/helpers/translation';
import Buttons from '../src/components/Buttons/Buttons';
import Pawlink from '../src/components/PawLink/PawLink';

import { filters } from '../src/helpers/pysFilter';

import './pys-types.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query allPYST($locale: String!, $group_name: String!) {
    allPysTypeBlock(filter: { lang: { eq: $locale }, tag: { eq: $group_name } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPysTypeTitleBlock(filter: { lang: { eq: $locale }, tag: { eq: $group_name } }) {
      edges {
        node {
          title
          sub_title
        }
      }
    }
    allPysTypeBreadcrumbBlock(filter: { lang: { eq: $locale }, tag: { eq: $group_name } }) {
      edges {
        node {
          name
        }
      }
    }
    allPysTypeData(filter: { lang: { eq: $locale }, tag: { eq: $group_name } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allDistPromosPromocionesSubBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          id
          title
          description
          pys_filter
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
            ctaText2
            cta2
          }
          description
        }
      }
    }
    allEntradasCombinadasBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          Entradas {
            title
            subTitle
            price
            paw_logo
            fl_logo
            caribe_logo
            pysFilter
            third_rate_price_web
            discount_logo
            locale_to_display
          }
        }
      }
    }
    allHotelsBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          stars
          pys_filter
        }
      }
    }
    allRestaurantsSubBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          located
          features
          possibleFilters1
          possibleFilters2
          pys_filter
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allRidesSubBlock(filter: { lang: { eq: $locale } }, sort: { fields: position, order: ASC }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          located
          features
          possibleFilters1
          possibleFilters2
          pys_filter
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allDestinyDiscoverParksBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id_block
          title
          cta
          ctaText
          size
          color
          localImage {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 250, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allSportDiscoverParksBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          text
          title_url
          localImage {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          image
          button1 {
            cta
            ctaText
            size
            color
          }
          button2 {
            cta
            ctaText
            size
            color
          }
        }
      }
    }
    allPysTypeRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
    allPysTypeDestiny(filter: { lang: { eq: $locale }, tag: { eq: $group_name } }) {
      edges {
        node {
          title
          cta
          ctaText
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
  }
`;

class PYS extends React.Component {
  state = {
    urlShare: `www.portaventuraworld.com${this.props.pageContext.url}`,
  };

  heimdallData = {
    name: this.props.data.allPysTypeTitleBlock.edges[0].node.title,
    subtitle: this.props.data.allPysTypeTitleBlock.edges[0].node.sub_title,
    image: {
      url: this.props.data.allPysTypeBlock.edges[0].node.localImage.childImageSharp.fluid,
    },
  };

  freyaData = {
    cards: this.props.data.allDistPromosPromocionesSubBlock.edges.map((item) => ({
      pysFilter: item.node.pys_filter,
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.image_alt,
      title: item.node.title,
      imgsize: item.node.imgsize,
      twoButton: true,
      buttons: {
        cta: item.node.buttons.cta,
        size: 'small',
        color: item.node.buttons.color,
        ctaText: item.node.buttons.ctaText,
        cta2: item.node.buttons.cta2,
        size2: 'small',
        color2: 'orange',
        ctaText2: item.node.buttons.ctaText2,
      },
    })),
  };

  freyaDataRestaurants = {
    cards: this.props.data.allRestaurantsSubBlock.edges.map((item) => ({
      pysFilter: item.node.pys_filter,
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.image_alt,
      title: item.node.title,
      imgsize: 'promoted',
      buttons: {
        cta: item.node.buttons.cta,
        size: 'alone',
        color: 'white',
        ctaText: item.node.buttons.ctaText,
      },
    })),
  };

  freyaDataDestiny = {
    isSlider: true,
    cards: this.props.data.allPysTypeDestiny.edges.map((item) => ({
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.image_alt,
      title: item.node.title,
      imgsize: 'promoted',
      buttons: {
        cta: item.node.cta,
        size: 'alone',
        color: 'white',
        ctaText: item.node.ctaText,
      },
    })),
  };

  freyaDataRides = {
    cards: this.props.data.allRidesSubBlock.edges.map((item) => ({
      pysFilter: item.node.pys_filter,
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.image_alt,
      title: item.node.title,
      imgsize: 'promoted',
      buttons: {
        cta: item.node.buttons.cta,
        size: 'alone',
        color: 'white',
        ctaText: item.node.buttons.ctaText.toUpperCase(),
      },
    })),
  };

  freyaDataDestination = {
    cards: this.props.data.allDestinyDiscoverParksBlock.edges.map((item) => ({
      pysFilter: parseInt(item.node.id_block, 10),
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.image_alt,
      title: item.node.title,
      imgsize: 'promoted',
      buttons: {
        cta: item.node.cta,
        size: 'alone',
        color: 'white',
        ctaText: item.node.ctaText,
      },
    })),
  };

  freyaDataSport = {
    cards: this.props.data.allSportDiscoverParksBlock.edges.map((item) => ({
      pysFilter: parseInt(item.node.id_block, 10),
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.image_alt,
      title: item.node.title,
      imgsize: 'promoted',
      buttons: {
        cta: item.node.button1.cta,
        size: 'alone',
        color: 'white',
        ctaText: item.node.button1.ctaText,
      },
    })),
  };

  titleH3Data = {
    title: tt('1. OPCIONES DE ENTRADAS', this.props.pageContext.locale),
    description: `${tt(
      'CON',
      this.props.pageContext.locale
    )} ${this.props.pageContext.options[0].toUpperCase()}`,
  };

  titleH3Data2 = {
    title: tt('2. OPCIONES DE ENTRADA + HOTEL', this.props.pageContext.locale),
    description: `${tt(
      'CON',
      this.props.pageContext.locale
    )} ${this.props.pageContext.options[0].toUpperCase()}`,
  };

  titleH3Data3 = {
    title: tt(
      '3. ATRACCIONES, RESTAURANTES, ESPECTÁCULOS Y ACTIVIDADES',
      this.props.pageContext.locale
    ),
    description: `${tt(
      'CON',
      this.props.pageContext.locale
    )} ${this.props.pageContext.options[0].toUpperCase()}`,
  };

  idunData = {
    title: tt('Este es tu plan', this.props.pageContext.locale).toUpperCase(),
    page: this.props.pageContext.group_name,
    cta: 'plan_your_stay',
    ctaText: tt('CAMBIAR PLAN', this.props.pageContext.locale),
    locale: this.props.pageContext.locale,
  };

  viliHotels = (data) => {
    const finalData = [];
    data.map((e) => {
      finalData.push(e.node);
    });
    return finalData;
  };

  render() {
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allPysTypeData.edges[0].node._0.title}
          description={this.props.data.allPysTypeData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={this.heimdallData} />
          <div className="general-index">
            <div className="pys-types-container">
              <Idun data={this.idunData} />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allPysTypeBreadcrumbBlock.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allPysTypeRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={this.props.data.allPysTypeBlock.edges[0].node.localImage.childImageSharp.fluid}
              />
              <BlueArrow />
              <div className="promos-recom">
                <H3 className="general-title" data={this.titleH3Data} />
                {/* <Vili
                  type="entradas"
                  locale={this.props.pageContext.locale}
                  data={filters.tickets(
                    this.props.data.allEntradasCombinadasBlock.edges,
                    this.props.pageContext.group_name,
                    this.props.pageContext.locale
                  )}
                /> */}
                <div className="button-container">
                  <Buttons
                    link={tt('/promociones', this.props.pageContext.locale)}
                    size="alone"
                    color="white"
                    text={tt('VER TODAS LAS TARIFAS', this.props.pageContext.locale)}
                  />
                </div>
                <div className="primary-title">
                  {tt('PROMOCIONES RECOMENDADAS', this.props.pageContext.locale)}
                </div>
                <div className="general-subtitle">{this.titleH3Data.description}</div>
                <Freya
                  data={filters.promotion_entradas(
                    this.freyaData,
                    this.props.pageContext.group_name
                  )}
                />
              </div>
              {this.props.pageContext.group_name === 'familia-1-dia' ||
              this.props.pageContext.group_name === 'amigos-1-dia' ? null : (
                <div className="promos-entrada-hotel">
                  <H3 className="general-title" data={this.titleH3Data2} />
                  <Vili
                    locale={this.props.pageContext.locale}
                    data={filters.hotel(
                      this.viliHotels(this.props.data.allHotelsBlock.edges),
                      this.props.pageContext.group_name
                    )}
                  />
                  <div className="button-container">
                    <Buttons
                      link={tt('/hoteles', this.props.pageContext.locale)}
                      size="alone"
                      color="white"
                      text={tt('VER NUESTROS HOTELES', this.props.pageContext.locale)}
                    />
                  </div>
                  <div className="primary-title">
                    {tt('PROMOCIONES RECOMENDADAS', this.props.pageContext.locale)}
                  </div>
                  <div className="general-subtitle">
                    {`${tt(
                      'CON',
                      this.props.pageContext.locale
                    )} ${this.props.pageContext.options[0].toUpperCase()}`}
                  </div>
                  <Freya
                    data={filters.promotion_hotel(
                      this.freyaData,
                      this.props.pageContext.group_name
                    )}
                  />
                </div>
              )}
              <div className="promos-varaidas">
                <H3 className="general-title" data={this.titleH3Data3} />
                <div className="primary-title">
                  {tt('ATRACCIONES RECOMENDADAS', this.props.pageContext.locale)}
                </div>
                <Freya
                  data={filters.rides(this.freyaDataRides, this.props.pageContext.group_name)}
                />
                <div className="primary-title">
                  {tt('RESTAURANTES RECOMENDADOS', this.props.pageContext.locale)}
                </div>
                <Freya
                  data={filters.restaurants(
                    this.freyaDataRestaurants,
                    this.props.pageContext.group_name
                  )}
                />
                <div className="primary-title">
                  {tt('ESPECTÁCULOS RECOMENDADOS', this.props.pageContext.locale)}
                </div>
                <Freya
                  data={filters.shows(this.freyaDataRides, this.props.pageContext.group_name)}
                />
                {this.props.pageContext.group_name === 'familia-1-dia' ||
                this.props.pageContext.group_name === 'amigos-1-dia' ? null : (
                  <>
                    <div className="primary-title">
                      {tt('ACTIVIDADES ALTERNATIVAS', this.props.pageContext.locale)}
                    </div>
                    <Freya data={this.freyaDataDestiny} />
                  </>
                )}
                <div className="footer-images">
                  <div className="share-ur-plan">
                    {tt('Compartir tu plan:', this.props.pageContext.locale)}
                  </div>
                  <div className="share-icons">
                    <FacebookShareButton url={this.state.urlShare}>
                      <FacebookIcon round size={32} />
                    </FacebookShareButton>
                    <TwitterShareButton url={this.state.urlShare} title={this.state.urlShare}>
                      <TwitterIcon round size={32} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={this.state.urlShare}>
                      <WhatsappIcon round size={32} />
                    </WhatsappShareButton>
                  </div>
                </div>
                <div className="button-container">
                  <Buttons
                    link={tt('/destino/planificar', this.props.pageContext.locale)}
                    size="alone"
                    color="white"
                    text={tt('CAMBIAR PLAN', this.props.pageContext.locale)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PYS;
