import './vili.scss';

import React from 'react';
import { injectIntl } from 'react-intl';
import { ViliCard } from './ViliCard';

export const Vili = injectIntl(props => {
  const { data, type, locale } = props;
  const cardMap = array => {
    if (array.length) {
      return array.map(element => <ViliCard locale={locale} type={type} data={element} />);
    }
  };

  return <div className={`vili-container ${type || ''}`}>{cardMap(data)}</div>;
});
